// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-you-js": () => import("./../../../src/pages/about-you.js" /* webpackChunkName: "component---src-pages-about-you-js" */),
  "component---src-pages-complaints-policy-js": () => import("./../../../src/pages/complaints-policy.js" /* webpackChunkName: "component---src-pages-complaints-policy-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-finding-true-wealth-index-js": () => import("./../../../src/pages/finding-true-wealth/index.js" /* webpackChunkName: "component---src-pages-finding-true-wealth-index-js" */),
  "component---src-pages-guides-js": () => import("./../../../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-important-information-js": () => import("./../../../src/pages/important-information.js" /* webpackChunkName: "component---src-pages-important-information-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-true-wealth-group-js": () => import("./../../../src/pages/true-wealth-group.js" /* webpackChunkName: "component---src-pages-true-wealth-group-js" */),
  "component---src-pages-who-are-we-js": () => import("./../../../src/pages/who-are-we.js" /* webpackChunkName: "component---src-pages-who-are-we-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

